<template>
  <div>
    <div id="scroll-top"></div>
    <div id="the-outer-document">
      <DocRenderPages
        v-if="contract.id"
        :documents="documents"
        :contract="contract"
      />
    </div>
    <span id="to-top"><a-icon type="up-circle" @click="toTop" /></span>
  </div>
</template>

<script>
import jq from "jquery";
import DocRenderPages from "@/components/documents/sections/DocRenderPages.vue";

export default {
  name: "ViewContractPreviewDocumentsSection",
  components: {
    DocRenderPages,
  },
  props: {
    contract: Object,
    documents: Array,
  },
  data() {
    return {};
  },
  methods: {
    toTop() {
      jq(".document-viewer").animate(
        {
          scrollTop: document.getElementById("scroll-top").offsetTop - 60,
        },
        500
      );
    },
  },
};
</script>

<style lang="sass" scoped>
#to-top
  position: absolute
  bottom: 10px
  right: 30px
  font-size: 35px
  cursor: pointer
  color: #fff
  opacity: 0.3
  transition: .3s
  &:hover
    opacity: 1
#the-outer-document
  max-width: 793px
  margin: 0 auto
</style>
