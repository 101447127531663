<template>
  <section>
    <div
      id="pdf-area"
      class="document-wrapper"
      style="font-family: 'Poppins', sans-serif"
    >
      <div v-for="(doc, index) in documents" :key="index">
        <div :id="`doc-${doc.id}`" v-show="doc.show">
          <DocRenderView
            v-if="!policy.loading"
            :id="doc.id"
            :docIndex="index"
            :contract="contract"
            :contractPolicies="policy.list"
          />
        </div>
      </div>

      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swadp"
        rel="stylesheet"
      />

      <v-style class="none">
        .actions, .none, .anticon-delete, .add-thing, .box-elements .type,
        .document-wrapper .prev-mode .box-elements .type, .document-wrapper
        .prev-mode .box-elements .add-thing, .document-wrapper .prev-mode
        .box-elements .empty-space { display: none !important; }
         p { margin-top: 0; margin-bottom: 1em; } table {
        border-collapse: collapse; } table { text-indent: initial;
        border-spacing: 2px; } .ql-align-center { text-align: center; } table td
        { color: #666; font-size: 13px; line-height: 14px; } b, strong {
        font-weight: 600 !important; } .ql-align-justify { text-align: justify;
        } .builder-container { font-size: 12px; } p { margin:0 0 10px 0
        !important; padding: 0 !important } h1, h2, h3, h4, h5, h6 { margin: 0
        !important; padding: 0 !important; font-weight: 500; line-height: 1.2;
        color: #000 } .element-wrapper { font-size: 12px; color: #666 }
        .page-content { padding: 0px !important } .ant-row { position: relative;
        height: auto; margin-right: 0; margin-left: 0; zoom: 1; display: block;
        box-sizing: border-box; } .ant-col-12 { display: block; box-sizing:
        border-box; width: 50%; } .ant-col-1, .ant-col-2, .ant-col-3,
        .ant-col-4, .ant-col-5, .ant-col-6, .ant-col-7, .ant-col-8, .ant-col-9,
        .ant-col-10, .ant-col-11, .ant-col-12, .ant-col-13, .ant-col-14,
        .ant-col-15, .ant-col-16, .ant-col-17, .ant-col-18, .ant-col-19,
        .ant-col-20, .ant-col-21, .ant-col-22, .ant-col-23, .ant-col-24 { flex:
        0 0 auto; float: left; } .ant-col-8 { display: block; box-sizing:
        border-box; width: 33.33333333%; } .ant-col-6 { display: block;
        box-sizing: border-box; width: 25%; } .ql-size-small { font-size: 9px;
        color: #000; line-height: 2; } .ql-size-large { font-size: 16px; color:
        #000; line-height: 1; } .ql-size-huge { font-size: 20px; color: #000;
        line-height: 1 } .ql-align-right { text-align: right; } .ant-col-24 {
        display: block; box-sizing: border-box; width: 100%; } .ant-form {
        line-height: 1.5 }
      </v-style>
    </div>
  </section>
</template>

<script>
import policyMixins from "@/mixins/policies/policyMixins";
import DocRenderView from "@/components/documents/sections/DocRenderView.vue";

export default {
  name: "DocRenderPages",
  props: {
    documents: Array,
    contract: Object,
  },
  components: {
    DocRenderView,
  },
  mixins: [policyMixins],
  mounted() {
    this.policy.filters.module = "contract";
    this.policy.filters.id = this.contract.id;
    this.policy.filters.showPolicy = 1;
    this.getPolicies();
  },
};
</script>

<style lang="sass" scoped>
.document-wrapper
  margin: 0 auto
  width: 802px
</style>

<style lang="sass">
#pdf-area
  .outer-box:hover .actions,
  .outer-box:hover .add-thing
    display: none !important

.document-wrapper
  font-size: 12px
  .content p
    margin-bottom: 10px !important
  h1, h2, h3, h4, h5, h6
    margin: 0 !important
    padding: 0 !important
    line-height: 1.2

.page-content.prev-mode
  height: 1200px !important
  .outer-box
    .add-thing, .empty-space, .type
      display: none !important
  .content
    background: #FFF
  .mb-10
    margin-bottom: 0 !important
  .box-elements
    border: 0
    padding: 0
    .type, .add-thing, .empty-space
      display: none !important
</style>
